<template>
  <div class="d-flex flex-column bg-dark text-white">
    <p class="text-center text-capitalize fw-bold mb-1 fs-2">sai silver group</p>
    <p class="text-center text-uppercase">Website Under Development</p>
    <div class="text-center">
      <img src="../assets/ssg.jpg" style="max-width: 100vw; max-height: 400px;" />
    </div>
    <div class="d-flex flex-column my-3">
      <div class="btn-group w-100">
        <a
          href="https://wa.me/+919355678400?text=Hello%20.."
          target="_blank"
          class="d-flex justify-content-center align-items-center btn btn-success"
          style="color: inherit; width: 50vw;"
        >
          <i class="bi pe-3 bi-whatsapp"></i>
          <p class="small mb-0 ls-1">Whatsapp</p>
        </a>
        <a
          href="tel:+919355678400"
          target="_blank"
          class="d-flex justify-content-center align-items-center btn btn-primary"
          style="color: inherit; width: 50vw;"
        >
          <i class="bi pe-3 bi-telephone-fill"></i>
          <p class="small mb-0 ls-1">Call Us</p>
        </a>
      </div>

      <a class="btn btn-dark mt-3" type="button" href="https://maps.app.goo.gl/DwNvuJQz9VTjUa3g7">Click for Google Location</a>

    </div>
    <div id="collapseExample" style="width: 100vw;" class="collapse show overflow-hidden">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14007.045058252015!2d77.13906!3d28.6369164!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d031f47ac2fcd%3A0x2d1c803a09e4a4c6!2sSAI%20SILVER%20STEEL%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1684651702344!5m2!1sen!2sin"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="row g-3 mt-1">
      <div class="col-md-6">
        <img src="../assets/1.jpg" width="100%" height="100%" style="object-fit: cover;" />
      </div>
      <div class="col-md-6">
        <img src="../assets/2.jpg" width="100%" height="100%" style="object-fit: cover;" />
      </div>
      <div class="col-md-6">
        <img src="../assets/3.jpg" width="100%" height="100%" style="object-fit: cover;" />
      </div>
      <div class="col-md-6">
        <img src="../assets/4.jpg" width="100%" height="100%" style="object-fit: cover;" />
      </div>
    </div>
    <footer id="myFooter" class="bg-dark">
        <div class="container-xl">
            <!-- <div class="d-block d-lg-flex justify-content-center justify-content-lg-between p-4 text-white">
                <div class="text-center" v-html="'Visitor Count: ' + count"></div>
            </div> -->
            <div class="d-block d-lg-flex justify-content-center justify-content-lg-between p-4 text-white">
                <div class="text-center">
                    © 2019 - 2022 Copyright:
                    <a class="text-reset td-none fw-bold" href="http://saisilvergroup.com/">
                        Sai Silver Group
                    </a>
                    . All rights reserved.
                </div>
                <div class="mt-2 mt-md-0 text-center">
                    Developed By:
                    <a class="text-reset td-none fw-bold" href="https://covisor.in">Covisor Infotech Pvt. Ltd.</a>
                </div>
            </div>
        </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'HomeView'
  // data () {
  //   return {
  //     count: 0
  //   }
  // },
  // created () {
  //   fetch('/api/visit')
  //     .then(res => res.json())
  //     .then(data => {
  //       this.count = data.count
  //       console.log('This page has been visited ' + data.count + ' times')
  //     })
  //     .catch(err => console.log(err))
  // }
}
</script>
