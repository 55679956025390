<template>
  <router-view v-slot="{Component}" mode="out-in">
    <transition name="slide">
      <component :is="Component" :key="$route.path"></component>
    </transition>
  </router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>
